.loading .spinner-border {
  color: white;
  font-size: 2.25rem;
  width: 4.5rem;
  height: 4.5rem;
  position: fixed;
  top: calc(50% - 2.25rem);
  left: calc(50% - 2.25rem);
  z-index: 10;
}

.loading-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
